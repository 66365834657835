export default {
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зателефонувати"])},
  "call_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній дзвінок"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новини"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["послугу"])},
  "all_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі новини"])},
  "NetworkInfrastructure Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мережева інфраструктура"])},
  "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Докладніше"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мапа проїзду"])},
  "studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Студія «Зіна дизайн»"])},
  "studio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["розробка сайту. Дніпро, 2021"])},
  "question_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАЛИШИЛИСЬ ПИТАННЯ?"])},
  "push_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для заповнення форми зворотнього зв'язку"])},
  "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Ім'я"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контактний email"])},
  "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефону"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш коментар"])},
  "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
  "your_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше замовлення"])},
  "your_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш коментар"])},
  "order_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити товар"])},
  "OUR REPRESENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАШЕ ПРЕДСТАВНИЦТВО"])},
  "order_goods_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити послугу"])},
  "order_a_call_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити зворотній дзвінок"])},
  "wait_for_a_call_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чекайте дзвінок найближчим часом"])},
  "thank_you_for_your_request_wait_for_a_call_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо за замовлення, чекайте дзвінок найближчим часом"])},
  "to_place_your_order_please_fill_out_the_form_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для оформлення Вашого замовлення, заповніть будь ласка форму зворотнього зв'язку"])},
  "your_name_and_patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Ім'я"])},
  "your_contact_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш контактний email"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефону"])},
  "buy_extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придбати додатково"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати"])},
  "InformationalSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформаційна безпека"])},
  "Home Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
  "IpTelephony Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip - ATC"])},
  "Search Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
  "ItInfrastructure Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT - інфраструктура"])},
  "BatchSolutions Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакетні рішення"])},
  "ClientsAndPartners Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнти і партнери"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
  "Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог"])},
  "BlogHumor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гумор"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог"])},
  "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити ще"])},
  "BlogInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цікава інформація"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціна"])},
  "Position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розташування"])},
  "AboutCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про компанію"])},
  "ClientsAndPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кліенти та партнери"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук по сайту"])},
  "Found in sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдено у роздiлах"])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шукати"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["результати"])},
  "Set Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплектуючі"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["результатів"])},
  "other_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["інші новини"])},
  "view_all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подивитися усі послуги"])},
  "order_a_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовити послугу"])},
  "Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдено"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервісні послуги"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детальніше"])},
  "Enter a query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть запит"])},
  "Sorry, nothing was found for your query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажаль, по Вашому запиту нiчого не знайдено"])},
  "Nothing found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нічого не знайдено"])},
  "Please enter search words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть, будь ласка, пошукові слова"])},
  "There are results on your request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По Вашому запиту Є результати"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["від"])}
}